var render, staticRenderFns
import script from "./detailBanner.vue?vue&type=script&lang=js&"
export * from "./detailBanner.vue?vue&type=script&lang=js&"
import style0 from "./detailBanner.vue?vue&type=style&index=0&id=32c559d4&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32c559d4",
  null
  
)

export default component.exports