import store from '@/store/index'

// 订单状态
export const orderStateList = [
  {
    text: '待付款',
    value: '0'
  },
  {
    text: '待使用',
    value: '1'
  },
  {
    text: '待发货',
    value: '2'
  },
  {
    text: '待签收',
    value: '3'
  },
  {
    text: '待评价',
    value: '4'
  },
  {
    text: '已完成',
    value: '5'
  },
  {
    text: '已关闭',
    value: '6'
  }
]

//酒店民宿
export const orderStateList2 = [
  {
    text: '待付款',
    value: '0'
  },
  {
    text: '待入住',
    value: '1'
  },
  {
    text: '待发货',
    value: '2'
  },
  {
    text: '待签收',
    value: '3'
  },
  {
    text: '待评价',
    value: '4'
  },
  {
    text: '已完成',
    value: '5'
  },
  {
    text: '已关闭',
    value: '6'
  },
  {
    text: '待确认',
    value: '102'
  }
]

//景点门票
export const orderStateList3 = [
  {
    text: '待付款',
    value: '0'
  },
  {
    text: '待使用',
    value: '1'
  },
  {
    text: '待发货',
    value: '2'
  },
  {
    text: '待签收',
    value: '3'
  },
  {
    text: '待评价',
    value: '4'
  },
  {
    text: '已完成',
    value: '5'
  },
  {
    text: '已关闭',
    value: '6'
  },
  {
    text: '出票中',
    value: '101'
  }
]
// 床类型
export const bedTypeList = [
  {
    text: '大床',
    name: '大床',
    value: '0'
  },
  {
    text: '双床',
    name: '双床',
    value: '1'
  },
  {
    text: '大床/双床',
    name: '大床/双床',
    value: '2'
  }
]
// 订单来源
export const orderSourceList = [
  {
    text: '商城',
    value: '1'
  },
  {
    text: '餐饮',
    value: '2'
  },
  {
    text: '民宿',
    value: '3'
  },
  {
    text: '采摘',
    value: '4'
  },
  {
    text: '垂钓',
    value: '5'
  }
]
// 退款情况
export const refundIsList = [
  {
    text: '等待处理',
    value: '1'
  },
  {
    text: '已退款',
    value: '2'
  },
  {
    text: '未退款',
    value: '3'
  },
  {
    text: '退款失败',
    value: '5'
  }
]
// 营业周期
export const operatingCycle = [
  {
    name: '周一',
    value: '1'
  },
  {
    name: '周二',
    value: '2'
  },
  {
    name: '周三',
    value: '3'
  },
  {
    name: '周四',
    value: '4'
  },
  {
    name: '周五',
    value: '5'
  },
  {
    name: '周六',
    value: '6'
  },
  {
    name: '周日',
    value: '7'
  }
]
// 分量单位
export const unitDataList = [
  {
    name: '盘',
    value: '盘'
  },
  {
    name: '个',
    value: '个'
  },
  {
    name: '份',
    value: '份'
  },
  {
    name: 'g',
    value: 'g'
  },
  {
    name: 'KG',
    value: 'KG'
  },
  {
    name: '两',
    value: '两'
  }
]
// 针对产品类型
export const productTypeList = () => {
  const { byProductType } = store.state.textFiles
  return [
    {
      name: '全场',
      value: '0',
      type: 'all'
    },
    ...byProductType
  ]
}

// 优惠券-发放方式
export const giveTypeList = [
  {
    name: '用户手动领取',
    text: '用户手动领取',
    value: '1'
  },
  {
    name: '自动发放到所有用户',
    text: '自动发放到所有用户',
    value: '2'
  },
  {
    name: '只限二维码领取',
    text: '只限二维码领取',
    value: '3'
  },
  {
    name: '营销活动',
    text: '营销活动',
    value: '4'
  },
  {
    name: '付款时自动领取',
    text: '付款时自动领取',
    value: '5'
  }
]

// 链接类型
export const relateTypeList = [
  {
    text: '外链',
    name: '外链',
    value: '1'
  },
  {
    text: '内部链接',
    name: '内部链接',
    value: '2'
  },
  {
    text: '商品',
    name: '商品',
    value: '3'
  },
  {
    text: '店铺',
    name: '店铺',
    value: '4'
  },
  {
    text: '活动',
    name: '活动',
    value: '5'
  },
  {
    text: '直播',
    name: '直播',
    value: '6'
  },
  {
    text: '专题页',
    name: '专题页',
    value: '7'
  },
  {
    text: '抽奖',
    name: '抽奖',
    value: '8'
  },
  {
    text: '正在开发中',
    name: '正在开发中',
    value: '-1'
  },
  {
    text: '无',
    name: '无',
    value: '-2'
  }
]
// 游玩天数
export const dayNumList = [
  {
    text: '1天',
    value: '1'
  },
  {
    text: '2天',
    value: '2'
  },
  {
    text: '3天',
    value: '3'
  },
  {
    text: '其他',
    value: '0'
  }
]
// 商户类型
export const subMerTypeList = [
  //   {
  //     name: '自然人',
  //     value: '1'
  //   },
  {
    name: '企业',
    value: '2'
  },
  {
    name: '个体工商户',
    value: '3'
  }
]
// 商户验证类型
export const subMerRuleTypeList = [
  {
    text: '待验证',
    value: '0'
  },
  {
    text: '等待审核',
    value: '1'
  },
  {
    text: ' 开通成功',
    value: '2'
  },
  {
    text: ' 开通失败',
    value: '3'
  }
]
// 证件类型
export const certificateTypeList = [
  {
    name: '身份证',
    value: 'I'
  },
  {
    name: '护照',
    value: 'P'
  },
  {
    name: '军官证',
    value: 'G'
  },
  {
    name: '其他证件',
    value: 'U'
  }
]
// 证件类型
export const companyCertTypeList = [
  {
    name: '营业执照',
    value: 'U'
  },
  {
    name: '组织机构代码证',
    value: 'Y'
  },
  {
    name: '统一社会信用代码',
    value: 'V'
  }
]
// 收款账户（选择企业必须用对公账户）
export const accountTypeList = [
  {
    name: '借记卡',
    value: '401'
  },
  {
    name: '企业户',
    value: '601'
  }
  //   {
  //     name: '二类户',
  //     value: '701'
  //   },
  //   {
  //     name: '三类户',
  //     value: '702'
  //   }
]
// 评论来源
export const relationTypeList = [
  {
    name: '农家乐',
    text: '农家乐',
    value: '0'
  },
  {
    name: '民宿农舍',
    text: '民宿农舍',
    value: '1'
  },
  {
    name: '农事体验',
    text: '农事体验',
    value: '2'
  },
  {
    name: '农家特产',
    text: '农家特产',
    value: '3'
  },
  {
    name: '乡村印象',
    text: '乡村印象',
    value: '4'
  },
  {
    name: '精彩活动',
    text: '精彩活动',
    value: '5'
  },
  {
    name: '农旅线路',
    text: '农旅线路',
    value: '6'
  },
  {
    name: '景点门票',
    text: '景点门票',
    value: '7'
  },
  {
    name: '酒店民宿',
    text: '酒店民宿',
    value: '8'
  }
]
// 推送端
export const pushPlat = [
  {
    name: 'IOS',
    text: 'IOS',
    value: '0'
  },
  {
    name: '安卓',
    text: '安卓',
    value: '1'
  },
  {
    name: '全平台',
    text: '全平台',
    value: '2'
  }
]

// 就业管理 - 部门
export const partList = [
  {
    name: '运营部',
    text: '运营部',
    value: '0'
  },
  {
    name: '开发部',
    text: '开发部',
    value: '1'
  },
  {
    name: '行政部',
    text: '行政部',
    value: '2'
  },
  {
    name: '物流部',
    text: '物流部',
    value: '3'
  }
]

// 宽带情况
export const broadbandSituation = () => {
  const { byBroadbandType } = store.state.textFiles
  return byBroadbandType
}
// 可否加床
export const extraBed = () => {
  const { byExtraBedType } = store.state.textFiles
  return byExtraBedType
}

// 是否禁烟
export const smoking = () => {
  const { byWhetherType } = store.state.textFiles
  return byWhetherType
}

// 无线WIFI
export const wirelessWIFI = () => {
  const { byWIFIType } = store.state.textFiles
  return byWIFIType
}

// 床型
export const bedType = () => {
  const { byBedType } = store.state.textFiles
  return byBedType
}

// 窗户
export const theWindow = () => {
  const { byWindowType } = store.state.textFiles
  return byWindowType
}

// 酒店星级
export const hotelStarRating = () => {
  const { byStarHotelType } = store.state.textFiles
  return byStarHotelType
}

// 所属品牌
export const brandOwnership = () => {
  const { byBrandType } = store.state.textFiles
  return byBrandType
}

// 票种
export const thirdTypeDataList = () => {
  const { byTicketType } = store.state.textFiles
  return byTicketType
}

//商品类型
export const goodsTypeList = () => {
  const { byGoodsType } = store.state.textFiles
  return byGoodsType
}

export const activeTypeList = [
  {
    name: '农家乐',
    text: '农家乐',
    value: '3'
  },
  {
    name: '民宿农舍',
    text: '民宿农舍',
    value: '2'
  },
  {
    name: '农事体验',
    text: '农事体验',
    value: '4'
  },
  //   {
  //     name: '农事体验-采摘',
  //     text: '农事体验-采摘',
  //     value: '4'
  //   },
  //   {
  //     name: '农事体验-垂钓',
  //     text: '农事体验-垂钓',
  //     value: '5'
  //   },
  {
    name: '景点',
    text: '景点',
    value: '1'
  },
  {
    name: '景点门票',
    text: '景点门票',
    value: '6'
  },
  {
    name: '酒店民宿（新）',
    text: '酒店民宿（新）',
    value: '7'
  }
]

export default {
  activeTypeList,
  orderStateList,
  orderStateList2,
  orderStateList3,
  bedTypeList,
  orderSourceList,
  refundIsList,
  operatingCycle,
  unitDataList,
  relateTypeList,
  dayNumList,
  subMerTypeList,
  subMerRuleTypeList,
  certificateTypeList,
  companyCertTypeList,
  accountTypeList,
  relationTypeList,
  pushPlat,
  partList,
  goodsTypeList,
  productTypeList,
  broadbandSituation,
  extraBed,
  smoking,
  wirelessWIFI,
  bedType,
  theWindow,
  hotelStarRating,
  brandOwnership,
  thirdTypeDataList
}
