<script>
import { Card, message, Row, Tabs, Col } from 'ant-design-vue'
import TableVIew from '../TableVIew'
import FormView from '../FormView'
import PopButton from '../PopButton'

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    form: {
      type: Object,
      default: () => {},
    },
    formData: {
      type: Object,
      default: () => {},
    },
    foot: {
      type: Function || Object,
      default: null,
    },
    typeData: {
      type: Object,
      default: () => {},
    },
    havedrag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeKey: '0',
      width: '',
    }
  },
  mounted() {
    let sider = this.getSider()
    this.width = sider.clientWidth
    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.width = sider.clientWidth
      }, 200)
    })
  },
  methods: {
    getSider() {
      return document.getElementsByClassName('ant-layout-sider-children')[0]
    },
    renderForm(item, index) {
      return <FormView props={item} form={this.form} ref={'form' + index} typeData={this.typeData}></FormView>
    },
    renderCardForm(item, index) {
      const form = this.renderForm(item, index)
      if (item.showCard == false) return <div class="empty-card">{form}</div>
      return (
        <Card title={item.title} style={{ marginTop: '10px' ,...item.cardStyle}}>
          {form}
        </Card>
      )
    },
    renderCardFormRow(item, index) {
      return (
        <Card title={item.title} style={{ marginTop: '10px' }}>
          <Row gutter={20}>
            {item.children.map((e) => {
              return e
            })}
          </Row>
        </Card>
      )
    },
    renderTable(item) {
      return (
        <TableVIew
          props={item}
          columns={item.columns}
          styles={{ marginTop: '10px' }}
          size={'small'}
          showAddButton={true}
          havedrag={this.havedrag}
          {...item}
        />
      )
    },
    renderRow(item) {
      if (!item.children || item.children.length == 0) return null
      return (
        <Row gutter={20} style={{ marginTop: '20px' }}>
          {item.children.map((e) => {
            return e
          })}
        </Row>
      )
    },
    renderTabs(item) {
      //  console.log(item, '==item==item.children.map')
      //  if (this.form[item.key] == undefined) return
      return (
        <Card style={{ marginTop: '10px' }} bodyStyle={{ paddingTop: '5px' }}>
          <Tabs onChange={(value) => (this.form[item.key] = value)}>
            {item.children.map((e, i) => {
              return (
                <Tabs.TabPane tab={e.title} key={i}>
                  {this.renderForm(e, 10 + i)}
                </Tabs.TabPane>
              )
            })}
          </Tabs>
        </Card>
      )
    },
    renderCardFormBox(item) {
      if (item.children instanceof Array) {
        return (
          <Row gutter={18}>
            {item.children.map((e, i) => {
              return (
                <Col span={e.cols || 12}>
                  {e.type ? this.renderView(e, i) : this.renderCardForm(e, i)}
                </Col>
              )
            })}
          </Row>
        )
      } else {
        return <div>渲染报错</div>
      }
    },
    renderView(e, i) {
      try {
        if (e.render) return e.render()
        if (!e.type) throw new Error('状态不存在')
        const name = e.type.slice(0, 1).toUpperCase() + e.type.slice(1)
        return this['render' + name](e, i)
      } catch (error) {
        return <div>渲染报错</div>
      }
    },
    async sumbit(item) {
      Object.keys(this.$refs).map((item) => {
        if (!this.$refs[item]) {
          delete this.$refs[item]
        }
      })

      const formRefKeys = Object.keys(this.$refs).filter((e) => e.indexOf('form') !== -1)
      const formRef = formRefKeys.map((e) => this.$refs[e])

      let obj = {}
      try {
        const subData = await Promise.all(formRef.map((e) => e.sumbit()))
        if (subData.every((e) => e)) {
          subData.forEach((e) => {
            obj = {
              ...obj,
              ...e,
            }
          })
          item.onClick(obj)
        } else {
          throw new Error('请完善表单')
        }
      } catch (error) {
        console.log('完善表单', error)
        message.error('请完善表单数据')
      }
    },
    getButtonClick(item) {
      if (item.sumbit) {
        this.sumbit(item)
      } else {
        if (item.onClick) {
          item.onClick()
        }
      }
    },
    renderButton(buttonList) {
      return (
        <div>
          {buttonList.map((e) => {
            return <PopButton item={e} onClick={() => this.getButtonClick(e)} />
          })}
        </div>
      )
    },
    renderFoot() {
      if (typeof this.foot == 'function' && this.foot) {
        return this.foot()
      }
      if (!this.foot) return null
      return (
        <div class="detail-form-foot" style={{ left: this.width + 'px' }}>
          {this.foot.left && this.renderButton(this.foot.left)}
          {this.foot.right && this.renderButton(this.foot.right)}
        </div>
      )
    },
  },
  render() {
    return (
      <div class="detail-form-group">
        {this.data.map((e, i) => {
          if (e) {
            return this.renderView(e, i)
          } else {
            return <div></div>
          }
        })}
        {this.foot && this.renderFoot()}
      </div>
    )
  },
  renderError(h, error) {
    console.log('报错', error)
  },
}
</script>
<style lang="less" scoped>
.empty-card {
  background: white;
  padding: 10px;
  margin-top: 10px;
}
.detail-form-group {
  //   overflow-y: hidden;
  //   overflow-x: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;

  //*去除详情滚动
  overflow-x: hidden;
  overflow-y: auto;
  height: 78vh;

  @media screen and (min-width: 2560px) {
    height: 88vh;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
.detail-form-foot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  background: white;
  height: 64px;
  border: 1px solid rgba(220, 222, 226, 1);
  padding-left: 25px;
  padding-right: 25px;
  position: fixed;
  right: 0px;
  bottom: 0px;
  z-index: 999;
}
</style>
